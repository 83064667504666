$(function() {
  $('form input[type="tel"]').inputmask("+7 (999) 999-99-99", {placeholder: "+7 (___) ___-__-__"});

  $('.fancybox').fancybox({
    width: 'auto',
    height: 'auto',

    minWidth: 0,
    minHeight: 0,

    padding: 0,

    autoSize: false,

    fitToView: true,

    scrolling: 'no',

    closeBtn: true,
    closeClick: false,
    nextClick: false,
    modal: false,
    loop: true,

    openEffect: 'none',
    closeEffect: 'none',

    helpers: {
      overlay: {
        closeClick: true,
        speedOut: 0,
        showEarly: true,
        locked: true
      }
    },

    beforeLoad: function () {
      this.title = $(this.element).attr('caption');
    },

    beforeShow: function () {
      $.fancybox.wrap.bind('contextmenu', function (e) {
        return false;
      });
    }
  });

  $(window).load(function() {
    $('.content-left-ad-bkgd').stick_in_parent({
      parent: '.left-col',
      sticky_class: 'content-left-ad-bkgd-stick',
      inner_scrolling: false,
    });
  });
});